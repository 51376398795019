<template>
  <el-input
    :id="id"
    :name="name"
    @keydown="handleKeydown($event, $event.target.value)"
    @click.stop
    v-model="_modelValue"
    :class="`${classes} w-full`"
    :size="size"
    :placeholder="placeholder"
    autocomplete="off"
  />
</template>

<script lang="ts">
import { Mixins, PropSync } from 'vue-property-decorator'
import InputOnlyNumberMixin from '@/components/mixins/InputOnlyNumberMixin.vue'
import { Options } from 'vue-class-component'
@Options({
  name: 'PhoneInputOnlyNumber',
})
export default class PhoneInputOnlyNumber extends Mixins(InputOnlyNumberMixin) {
  @PropSync('modelValue', { type: String }) _modelValue?: string
  precision = 0
}
</script>
