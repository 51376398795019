<template>
  <div v-loading.fullscreen="loading">
    <div class="flex pb-8 border-solid border-b-1 border-gray-300 header-container">
      <div class="flex-grow items-center flex header-container__left">
        <PageHeader
          class="pr-6"
          :isHideRefresh="true"
          :title="$t(`client.${id ? 'client_edit' : 'client_registration'}`)"
          @load:refreshList="initList"
        />
        <el-button
          type="primary"
          class="btn-original rounded-full w-28"
          :disabled="isSaveDisabled"
          @click="onSaveClient('refClientForm')"
        >
          {{ $t('save') }}
        </el-button>
      </div>
      <div class="flex-grow flex justify-end header-container__right">
        <el-button type="primary" @click="backToClientList()">
          {{ $t('client.client_list') }}
        </el-button>
      </div>
    </div>
    <el-form
      class="form-container"
      status-icon
      :label-position="'left'"
      label-width="220px"
      :rules="rules"
      :model="clientForm"
      ref="refClientForm"
    >
      <div class="py-8 text-lg">
        <div class="w-12/12 text-lg common-form-portal">
          <el-form-item prop="clientName" :label="$t('client.client_name')">
            <el-input v-model="clientForm.clientName" name="clientName" autocomplete="on" size="medium"></el-input>
          </el-form-item>
          <div class="pb-3">
            <h4 class="mb-5 text-lg font-medium">{{ $t('client.client_contact') }}</h4>
            <div class="sub-common-form">
              <el-form-item prop="address.country" :label="$t('client.country')">
                <el-select
                  @change="updateClientContactNumber"
                  :placeholder="$t('barcode_type.select')"
                  filterable
                  clearable
                  default-first-option
                  v-model="clientForm.address.country"
                  class="w-5/12"
                >
                  <el-option-group
                    v-for="(group, groupKey) in countries"
                    :key="groupKey"
                    :label="$t(`countryGroup.${groupKey}`)"
                  >
                    <el-option
                      v-for="country in group"
                      :key="country"
                      :value="country"
                      :label="$t(`countryList.${country}`)"
                    >
                      {{ $t(`countryList.${country}`) }}
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
              <div>
                <div class="flex">
                  <div class="w-6/12">
                    <el-form-item prop="address.postalCode" :label="$t('client.postal_code')">
                      <el-input
                        class="w-full"
                        v-model="clientForm.address.postalCode"
                        :placeholder="masks.postalCode.replaceAll('#', '_')"
                        v-maska="masks.postalCode"
                        name="postalCode"
                        autocomplete="on"
                        size="medium"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="w-6/12">
                    <el-form-item prop="address.prefecture" :label="$t('client.prefectures')">
                      <el-input
                        class="w-full"
                        v-model="clientForm.address.prefecture"
                        name="prefecture"
                        autocomplete="on"
                        size="medium"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <el-form-item prop="address.address1" :label="$t('client.address1')">
                <el-input
                  v-model="clientForm.address.address1"
                  name="address1"
                  autocomplete="on"
                  size="medium"
                ></el-input>
              </el-form-item>
              <el-form-item prop="address.address2" :label="$t('client.address2')">
                <el-input
                  v-model="clientForm.address.address2"
                  name="address2"
                  autocomplete="on"
                  size="medium"
                ></el-input>
              </el-form-item>
              <el-form-item prop="address.phoneNumber" :label="$t('client.phone_number')">
                <el-input
                  v-model="clientForm.address.phoneNumber"
                  :placeholder="masks.phoneNumber.replaceAll('#', '_')"
                  v-maska="masks.phoneNumber"
                  name="phoneNumber"
                  autocomplete="on"
                  size="medium"
                ></el-input>
              </el-form-item>
              <el-form-item prop="address.companyName" :label="$t('client.company_name')">
                <el-input
                  v-model="clientForm.address.companyName"
                  name="companyName"
                  autocomplete="on"
                  size="medium"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="pt-8 pb-4 mb-4 border-solid border-t-1 border-b-1 border-gray-300">
            <h4 class="mb-5 text-lg font-medium">{{ $t('client.information_contact_main_person') }}</h4>
            <div class="sub-common-form">
              <div>
                <div class="flex">
                  <div class="w-6/12">
                    <el-form-item prop="clientUser.lastName" :label="$t('last_name')">
                      <el-input
                        class="w-full"
                        v-model="clientForm.clientUser.lastName"
                        name="lastName"
                        autocomplete="on"
                        size="medium"
                      ></el-input>
                    </el-form-item>
                  </div>

                  <div class="w-6/12">
                    <el-form-item prop="clientUser.firstName" :label="$t('first_name')">
                      <el-input
                        class="w-full"
                        v-model="clientForm.clientUser.firstName"
                        name="firstName"
                        autocomplete="on"
                        size="medium"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <el-form-item prop="clientUser.email" :label="$t('client.contact_emaill')">
                <el-input v-model="clientForm.clientUser.email" name="email" autocomplete="on" size="medium"></el-input>
              </el-form-item>
              <el-form-item :label="$t(`client.change_password`)" v-if="id">
                <div class="flex">
                  <div class="w-1/12">
                    <el-checkbox v-model="isEditPassword" @change="onChangeEditPassword"></el-checkbox>
                  </div>
                  <div class="w-11/12">
                    <el-form-item prop="clientUser.password">
                      <el-input
                        :disabled="!isEditPassword"
                        type="password"
                        v-model="clientForm.clientUser.password"
                        name="password"
                        autocomplete="on"
                        size="medium"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </el-form-item>
              <el-form-item prop="clientUser.password" :label="$t('client.password')" v-else>
                <el-input
                  type="password"
                  v-model="clientForm.clientUser.password"
                  name="password"
                  autocomplete="on"
                  size="medium"
                ></el-input>
              </el-form-item>
              <el-form-item prop="clientUser.phone.countryCode" :label="$t('client.contact_phone_number')">
                <div class="flex">
                  <div class="w-1/3">
                    <el-form-item prop="clientUser.phone.countryCode">
                      <el-select
                        :placeholder="$t('barcode_type.select')"
                        filterable
                        v-model="clientForm.clientUser.phone.countryCode"
                        class="w-full"
                        size="medium"
                        @change="updatePhoneNumber"
                      >
                        <el-option-group
                          v-for="(group, groupKey) in countryList"
                          :key="groupKey"
                          :label="$t(`countryGroup.${groupKey}`)"
                        >
                          <el-option
                            v-for="(country, key) in group"
                            :key="key"
                            :value="key"
                            :label="getCountryName(country)"
                          >
                            {{ getCountryName(country) }}
                          </el-option>
                        </el-option-group>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="w-2/3">
                    <el-form-item prop="clientUser.phone.number">
                      <PhoneInputOnlyNumber v-model="clientForm.clientUser.phone.number" name="phoneNumber" />
                    </el-form-item>
                  </div>
                </div>
              </el-form-item>
              <template v-if="isSetDisplayedBarcodeByClient">
                <el-form-item prop="clientUser.setPresetSearch" :label="$t('using_search_preset')">
                  <div class="flex">
                    <el-checkbox v-model="clientForm.clientUser.setPresetSearch" name="setPresetSearch" />
                  </div>
                </el-form-item>
                <div class="preset-block relative fit-content" @click="onPresetCliked">
                  <el-form-item prop="clientUser.setPresetSearch" :label="$t('search_preset')">
                    <el-tooltip placement="top" effect="dark">
                      <template v-slot:content>
                        <span>{{ $t('perform_search_with_this_preset') }}</span>
                      </template>
                      <div class="flex search-container">
                        <el-form disabled>
                          <SearchForm
                            ref="searchForm"
                            :version="searchPreset.version"
                            v-model:searchConditionBlocks="searchPreset.searchConditionBlocks"
                            v-model:logicalOperator="searchPreset.logicalOperator"
                          />
                        </el-form>
                      </div>
                    </el-tooltip>
                  </el-form-item>
                </div>
                <el-form-item :label="$t('Staff')">
                  <el-transfer
                    v-model="clientForm.clientUser.carrierIds"
                    :filter-placeholder="$t('Staff')"
                    :titles="[$t('unassigned'), $t('assigned')]"
                    :props="{
                      key: 'id',
                      label: 'fullName',
                    }"
                    :data="availableCarrierList"
                    filterable
                  />
                </el-form-item>
                <el-form-item prop="entitledPortalUIViews" :label="$t('projects.project_visibility_setting')">
                  <el-transfer
                    v-model="clientForm.clientUser.visibleProjectCodes"
                    :filter-placeholder="$t('projects.projectName')"
                    :titles="[$t('projects.invisible_project'), $t('projects.visible_project')]"
                    :props="{
                      key: 'value',
                      label: 'label',
                    }"
                    :data="projectList"
                    filterable
                  />
                </el-form-item>
              </template>
            </div>
          </div>
          <el-form-item prop="isActive" :label="$t('status')">
            <el-checkbox v-model="clientForm.isActive" :disabled="!clientForm.isEdit">{{ $t('active') }}</el-checkbox>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script lang="ts">
import SearchForm from '@/components/barcodeSearch/SearchForm.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import PhoneInputOnlyNumber from '@/components/common/PhoneInputOnlyNumber.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import {
  CLEAR_DETAIL_CLIENT,
  FETCH_CLIENT,
  FETCH_COUNTRIES,
  FETCH_STAFF,
  LOAD_ACTIVE_PROJECTS_LIST,
  LOAD_CLIENT_LIST,
  SET_PAGE_NOT_FOUND,
} from '@/store/actions'
import { saveClient } from '@/utils/api'
import { POPULAR_COUNTRIES, POPULAR_COUNTRY_LIST } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import {
  buildSearchUrlQuery,
  getInitClientDetailState,
  initDataSearch,
  removeCharacterPhoneNumber,
} from '@/utils/helpers'
import { openMessage } from '@/utils/utils'
import parsePhoneNumber, { AsYouType, CountryCode } from 'libphonenumber-js'
import cloneDeep from 'lodash/cloneDeep'
import { maska } from 'maska'
import { OTHER_COUNTRY_LIST, PHONE_MASK, ZIPCODE_MASK } from 'smartbarcode-web-core/src/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeSearchForm,
  IClient,
  ICountryCode,
  IProject,
  ISearchConditionBlock,
  IStaff,
} from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { ProvideReactive, Watch } from 'vue-property-decorator'
interface IOption {
  value: string
  label: string
  disabled: boolean
}
@Options({
  components: { PageHeader, PhoneInputOnlyNumber, SearchForm },
  directives: { maska },
  name: 'ClientForm',
})
export default class ClientForm extends mixins(ValidateForm) {
  @ProvideReactive() projectVersion = 0

  isSaveDisabled = false
  clientForm = {} as IClient
  isEditPassword = false
  countries: {
    popularCountries: string[]
    allCountries: string[]
  } = {
    popularCountries: [],
    allCountries: [],
  }

  loading = false
  projectData: IOption[] = []

  get projectList() {
    return this.projectData.map((project) => ({
      ...project,
      disabled: this.isPresetSearchAvail,
    }))
  }

  get isSetDisplayedBarcodeByClient() {
    return this.$store.state.profile?.organization?.setDisplayedBarcodeByClient || false
  }

  get carrierIds() {
    return this.clientForm.clientUser.carrierIds
  }

  get searchPreset(): IBarcodeSearchForm {
    const preset = this.clientForm.clientUser.condition ?? initDataSearch()
    preset.searchConditionBlocks.forEach(async (block: ISearchConditionBlock) => {
      // specially handle projectId
      const firstCond = block.searchConditions[0]
      if (firstCond.key === 'projectId') {
        block.searchConditions.splice(0, 1)
      }

      block.searchConditions.forEach((condition) => {
        // specially handle subKey
        const keys = (condition.key ?? '').split('.')
        if (keys.length === 3) {
          condition.key = `${keys[0]}.${keys[1]}`
          condition.subKey = keys[2]
        }
      })
    })

    return Object.freeze({
      ...preset,
      version: preset.version ?? 0,
    })
  }

  get isPresetSearchAvail() {
    return this.clientForm.clientUser.setPresetSearch
  }

  get getCarrierList() {
    return this.$store.state.carrier?.staffs || []
  }

  get availableCarrierList() {
    const staffs: IStaff[] = this.getCarrierList

    return staffs.filter(
      (staff) => staff.clientIds?.includes(this.clientForm.clientUser.id || '') || staff.clientIds?.length === 0
    )
  }

  onPresetCliked() {
    this.$router.push({ name: 'barcodeSearch', query: buildSearchUrlQuery(this.searchPreset, 1) })
  }

  async fetchProjects() {
    if (isEmpty(this.$store.state.project?.activeProjects)) {
      await this.$store.dispatch(LOAD_ACTIVE_PROJECTS_LIST)
    }

    this.projectData = this.$store.state.project?.activeProjects.map(
      (item: IProject) =>
        (({
          value: item.code ?? '',
          label: item.name ?? '',
          disabled: this.isPresetSearchAvail,
        } as unknown) as IOption)
    )
  }

  @Watch('clientForm.clientUser.phone.number')
  onPhoneChange() {
    const phoneNumber = parsePhoneNumber(
      this.clientForm.clientUser.phone.number || '',
      this.clientForm.clientUser.phone.countryCode as CountryCode
    )
    if (phoneNumber) {
      this.clientForm.clientUser.phone.number = new AsYouType(
        this.clientForm.clientUser.phone.countryCode as CountryCode
      )
        .input(this.clientForm.clientUser.phone.number || '')
        .replaceAll(' ', '-')
    }
  }

  formatPhoneValidate = (rule: string, value: string, callback: Function) => {
    if (isEmpty(value)) return callback(new Error(this.messages.required))

    const phoneNumber = parsePhoneNumber(
      this.clientForm.clientUser.phone.number || '',
      this.clientForm.clientUser.phone.countryCode as CountryCode
    )

    if (phoneNumber) {
      if (phoneNumber.isValid()) {
        callback()
      } else {
        callback(new Error(this.messages.number))
      }
    } else {
      callback(new Error(this.messages.number))
    }
  }

  formatAddressPhoneValidate = (rule: string, value: string, callback: Function) => {
    if (isEmpty(value)) {
      return callback(new Error(this.messages.required))
    }

    setTimeout(() => {
      let isValid = false
      if (this.masks.phoneNumber) {
        if (this.masks.phoneNumber.length === value.length) isValid = true
        if (this.masks.phoneNumber.length === PHONE_MASK.DEFAULT.length) isValid = true
      } else {
        if (value.length <= 11) isValid = true
      }
      if (isValid) {
        callback()
      } else {
        callback(new Error(this.messages.number))
      }
    }, 100)
  }

  rules = {
    clientName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    address: {
      country: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      postalCode: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      prefecture: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      address1: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      phoneNumber: [{ required: true, validator: this.formatAddressPhoneValidate, trigger: 'blur' }],
      companyName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    },
    clientUser: {
      email: [{ required: true, validator: this.requiredEmailValidate, trigger: 'blur' }],
      password: [{ required: true, validator: this.requiredPasswordValidate, trigger: 'blur' }],
      firstName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      lastName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
      phone: {
        number: [{ required: true, validator: this.formatPhoneValidate, trigger: 'blur' }],
        countryCode: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
      },
    },
  }

  get countryList() {
    return {
      popularCountries: POPULAR_COUNTRY_LIST,
      allCountries: OTHER_COUNTRY_LIST,
    } as {
      popularCountries: Record<string, ICountryCode>
      allCountries: Record<string, ICountryCode>
    }
  }

  get masks() {
    return {
      phoneNumber: PHONE_MASK[this.clientForm?.address?.country] || PHONE_MASK.DEFAULT,
      postalCode: ZIPCODE_MASK[this.clientForm?.address?.country] || '',
      dimension: '#*.#',
    }
  }

  updatePhoneNumber() {
    this.clientForm.clientUser.phone.number = ''
  }

  updateClientContactNumber() {
    this.clientForm.address.phoneNumber = ''
  }

  onSaveClient(formName: string) {
    this.isSaveDisabled = true
    this.$refs[formName].validate((valid: string) => {
      if (valid) {
        this.saveClient()
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        this.isSaveDisabled = false
        return false
      }
    })
  }

  get id() {
    return this.$route.params.id
  }

  async saveClient() {
    try {
      this.loading = true
      const newPhoneFormat = removeCharacterPhoneNumber(this.clientForm?.clientUser?.phone.number)
      const newData = {
        ...this.clientForm,
        clientUser: {
          ...this.clientForm.clientUser,
          phone: {
            ...this.clientForm.clientUser.phone,
            number: newPhoneFormat,
          },
          condition: cloneDeep(this.$store.state.client.client.clientUser.condition),
        },
      }
      await saveClient({ ...newData, ...{ id: this.id } }, this.isEditPassword)
      openMessage(this.$t('save_successful'), 'success')

      await this.$store.dispatch(LOAD_CLIENT_LIST)
      this.backToClientList()
    } catch (err) {
      errorHandler(err)
    } finally {
      this.isSaveDisabled = false
      this.loading = false
    }
  }

  onChangeEditPassword() {
    if (this.id) {
      if (this.isEditPassword) {
        this.rules.clientUser.password = [{ required: true, validator: this.requiredPasswordValidate, trigger: 'blur' }]
      } else {
        this.rules.clientUser.password = []
      }
    }
  }

  backToClientList() {
    this.$router.push({ name: 'clients' })
  }

  async loadClientForm() {
    try {
      this.clientForm = getInitClientDetailState()
      if (this.id) {
        this.loading = true
        const promises = [this.$store.dispatch(FETCH_CLIENT, this.id)]
        if (this.isSetDisplayedBarcodeByClient) {
          promises.push(this.$store.dispatch(FETCH_STAFF))
        }
        await Promise.all(promises)
      } else {
        this.$store.commit(CLEAR_DETAIL_CLIENT)
      }
    } catch (error) {
      this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'client.client' })
    } finally {
      this.loading = false
    }
  }

  @Watch('$store.state.client.client')
  getInfo() {
    this.clientForm = cloneDeep(this.$store.state.client.client)
  }

  async initList() {
    this.countries.popularCountries = { ...POPULAR_COUNTRIES }
    if (!this.$store.state.profile?.isLoadCountry) {
      this.$store.dispatch(FETCH_COUNTRIES).then(() => {
        this.countries.allCountries = this.$store.state.profile?.countries
      })
    } else {
      this.countries.allCountries = this.$store.state.profile?.countries
    }

    this.onChangeEditPassword()
    this.messages.required = this.$t('this_field_is_required')
    this.messages.number = this.$t('please_enter_numeric')
    this.messages.email = this.$t('email_format_incorrect')
    this.messages.password = this.$t('please_enter_password')
    await this.loadClientForm()
  }

  async created() {
    await this.initList()
    await this.fetchProjects()
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';

.preset-block :deep() {
  .search-container {
    width: fit-content;
    padding-left: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    border: 3px solid transparent;
    &:hover {
      border: 3px solid $dark-grey;
      background-color: $light-grey;
      border-radius: 10px;
    }
    * {
      cursor: pointer !important;
    }
  }

  .el-form-item__content {
    line-height: 12px !important;
  }
}

:deep() {
  .el-transfer-panel {
    width: 350px;
  }
}
</style>
