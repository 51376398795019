
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import IconRefresh from '@/components/svg/IconRefresh.vue'

@Options({
  components: { IconRefresh },
  emits: ['load:refreshList'],
  name: 'PageHeader',
})
export default class PageHeader extends Vue {
  @Prop({ type: String }) readonly title: string = ''
  @Prop({ type: Boolean }) readonly isHideRefresh: boolean = false

  fetchList() {
    this.$emit('load:refreshList')
  }
}
