
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { ICountryCode } from 'smartbarcode-web-core/src/utils/types/index'
import { validateEmail } from '@/utils/validator'
import { Vue, Options } from 'vue-class-component'

@Options({
  components: {
    name: 'ValidateForm',
  },
})
export default class ValidateForm extends Vue {
  messages = {
    required: '',
    number: '',
    email: '',
    password: '',
    notZeroMessage: '',
    url: '',
    variable: '',
    fileNameIncorrect: '',
    ipAddressFormat: '',
  }

  mounted() {
    this.messages.required = this.$t('this_field_is_required')
    this.messages.number = this.$t('phone_format_incorrect')
    this.messages.email = this.$t('email_format_incorrect')
    this.messages.notZeroMessage = this.$t('this_field_must_not_zero')
    this.messages.url = this.$t('url_format_incorrect')
    this.messages.variable = this.$t('variable_format_incorrect')
    this.messages.fileNameIncorrect = this.$t('file_name_incorrect')
    this.messages.ipAddressFormat = this.$t('ip_format_incorrect')
  }

  requiredValidate = (rule: string, value: string, callback: Function) => {
    if (isEmpty(value)) {
      callback(new Error(this.messages.required))
    } else {
      callback()
    }
  }

  requiredVariableValidate = (rule: string, value: string, callback: Function) => {
    if (isEmpty(value)) {
      return callback(new Error(this.messages.required))
    }
    const urlRegex = /^\{\{.*\}\}$/
    if (urlRegex.test(value)) {
      callback()
    } else {
      callback(new Error(this.messages.variable))
    }
  }

  requiredURLValidate = (rule: string, value: string, callback: Function) => {
    if (isEmpty(value)) {
      return callback(new Error(this.messages.required))
    }

    const urlRegex = /^(ht|f)tp(s?):\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-.?,'/\\+&amp;%$#_]*)?$/

    setTimeout(() => {
      if (urlRegex.test(value)) {
        callback()
      } else {
        callback(new Error(this.messages.url))
      }
    }, 100)
  }

  requiredPhoneValidate = (rule: string, value: string, callback: Function) => {
    if (isEmpty(value)) {
      return callback(new Error(this.messages.required))
    }

    const phoneReg = /^\s*(?:\+?(\d{1,3})) ?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm
    setTimeout(() => {
      if (phoneReg.test(value)) {
        callback()
      } else {
        callback(new Error(this.messages.number))
      }
    }, 100)
  }

  requiredEmailValidate = (rule: string, value: string, callback: Function) => {
    if (value === '') {
      return callback(new Error(this.messages.required))
    }

    setTimeout(() => {
      if (validateEmail(value)) {
        callback()
      } else {
        callback(new Error(this.messages.email))
      }
    }, 100)
  }

  requiredPasswordValidate = (rule: string, value: string, callback: Function) => {
    if (value === '') {
      return callback(new Error(this.messages.required))
    }
    const passReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,32}$/g
    setTimeout(() => {
      if (passReg.test(value) && value.length >= 8) {
        callback()
      } else {
        callback(new Error(this.messages.password))
      }
    }, 100)
  }

  ipRequiredValidate = (rule: string, value: string, callback: Function) => {
    if (value === '') {
      return callback(new Error(this.messages.required))
    }
    const ipReg = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    setTimeout(() => {
      if (ipReg.test(value) && value.length >= 8) {
        callback()
      } else {
        callback(new Error(this.messages.ipAddressFormat))
      }
    }, 100)
  }

  ipValidate = (rule: string, value: string, callback: Function) => {
    if (value.trim() !== '') {
      const ipReg = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
      setTimeout(() => {
        if (ipReg.test(value) && value.length >= 8) {
          callback()
        } else {
          callback(new Error(this.messages.ipAddressFormat))
        }
      }, 100)
    } else {
      callback()
    }
  }

  passwordValidate = (rule: string, value: string, callback: Function) => {
    if (value.trim() !== '') {
      const passReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,32}$/g
      setTimeout(() => {
        if (passReg.test(value) && value.length >= 8) {
          callback()
        } else {
          callback(new Error(this.messages.password))
        }
      }, 100)
    } else {
      callback()
    }
  }

  numberAndNotZeroValidate = (rule: string, value: string, callback: Function) => {
    if (!value || value === '' || Number(value) === 0) {
      callback(new Error(this.messages.notZeroMessage))
    } else {
      callback()
    }
  }

  getCountryName(country: ICountryCode) {
    return `${this.$t(`countryList.${country.code}`)}${country.dialCode ? ` (${country.dialCode})` : ''}`
  }
}
