
import { Mixins, PropSync } from 'vue-property-decorator'
import InputOnlyNumberMixin from '@/components/mixins/InputOnlyNumberMixin.vue'
import { Options } from 'vue-class-component'
@Options({
  name: 'PhoneInputOnlyNumber',
})
export default class PhoneInputOnlyNumber extends Mixins(InputOnlyNumberMixin) {
  @PropSync('modelValue', { type: String }) _modelValue?: string
  precision = 0
}
