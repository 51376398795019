<template>
  <div class="flex header-text-container pr-6">
    <h4 class="font-bold text-lg truncate leading-normal items-center flex mr-4">
      {{ title }}
    </h4>
    <a v-if="!isHideRefresh" class="flex items-center btn-refresh" @click="fetchList()"><IconRefresh /></a>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import IconRefresh from '@/components/svg/IconRefresh.vue'

@Options({
  components: { IconRefresh },
  emits: ['load:refreshList'],
  name: 'PageHeader',
})
export default class PageHeader extends Vue {
  @Prop({ type: String }) readonly title: string = ''
  @Prop({ type: Boolean }) readonly isHideRefresh: boolean = false

  fetchList() {
    this.$emit('load:refreshList')
  }
}
</script>
